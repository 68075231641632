import clientHttp from './clientHttp'
const CACHE_KEY = 'apiCache';
const CACHE_EXPIRY_KEY = 'apiCacheExpiry';
const CACHE_EXPIRY_TIME = 1; //60 * 60 * 1000;

export const saveToCache = (key, data, expiryTime = CACHE_EXPIRY_TIME) => {
  const expiry = Date.now() + expiryTime;
  sessionStorage.setItem(`${CACHE_KEY}_${key}`, JSON.stringify(data));
  sessionStorage.setItem(`${CACHE_EXPIRY_KEY}_${key}`, expiry.toString());
}

const getFromCache = (key) => {
  const expiry = sessionStorage.getItem(`${CACHE_EXPIRY_KEY}_${key}`);
  if (expiry && Date.now() > parseInt(expiry)) {
    sessionStorage.removeItem(`${CACHE_KEY}_${key}`);
    sessionStorage.removeItem(`${CACHE_EXPIRY_KEY}_${key}`);
    return null;
  }
  return JSON.parse(sessionStorage.getItem(`${CACHE_KEY}_${key}`));
}

export const fetchWithCache = async (url, expiryTime = CACHE_EXPIRY_TIME) => {
    let dataCached = true
    const cacheKey = btoa(url);
    const cachedData = getFromCache(cacheKey);

    if (cachedData) return { data: cachedData, dataCached };
  
    const response = await clientHttp.get(url);
    const data = response.data;
  
    saveToCache(cacheKey, data, expiryTime);
    
    dataCached = false
    
    return { data, dataCached };
  }