import { format } from "@formkit/tempo"
import clientHttp from '../tools/clientHttp'
import UserProfile from "../data";
const user = UserProfile.getUserData();
const URL = "/inventories";
const URL_STOCK_BY_PRODUCT = "/inventories/stock-by-product";
let inventoryItems = [];
let langDate = "es";
let inventory = [];

export const getStockByProduct = async (productQuantityListDto) => {
    return clientHttp.post(`${URL_STOCK_BY_PRODUCT}`, productQuantityListDto)
}

