import { format } from "@formkit/tempo"
import clientHttp from '../tools/clientHttp'
const URL = "/categories";
let categories = [];
export const saveCategory = async (category) => {
    return clientHttp.post(`${URL}`, category)
}

export const getCategories = async () => {
    console.log("getCategories")
    if(categories.length > 0) return categories;
    categories = (await clientHttp.get(`${URL}`)).data.map(category => ({
        text: category.name,
        value: category.id
    }))
    return categories;
}

export const getCategoryById = async (id) => {
    return clientHttp.get(`${URL}/${id}`)
}

export const getCategoryByStatus = async (statuses) => {
    const allCategories = await getCategories();
    const statusArray = Array.isArray(statuses) ? statuses : [statuses];
    return allCategories.filter(category => 
        statusArray.some(status => 
            category.status.toLowerCase() === status.toLowerCase()
        )
    );
}

export const getCategoryByEmployee = async (employee) => {
    const allCategories = await getCategories();
}